import { AboutTwoData } from "@/data";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import zastoMiSlika from "@/images/aboutPage/godineIskustva.jpeg";
import Img from "./img";

const AboutTwo = () => {
  const { blockTitle, lists, offer } = AboutTwoData;
  
  return (
    <section className="about_service_area section_padding">
      <Container>
        <Row>
          <Col lg={6}>
            <Img src={zastoMiSlika} className="img-fluid" alt="Responsive image" />
          </Col>
          <Col lg={6}>
            <div className="about_service_right">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">{blockTitle.tagline}</h4>
                <h1>{blockTitle.title}</h1>
              </div>
              <ul>
                {lists.map(({ text }, index) => (
                  <li key={index}>
                    <i className="fa fa-check-circle"></i> {text}
                  </li>
                ))}
              </ul>
              <div className="about_service_discount text-center">
                <h1>{offer.tagline}</h1>
                <p>{offer.text}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutTwo;
